import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import { clientNameService, emailService, sessionCacheStorage, slugService } from '../services';
import { ROUTING_CONFIG, REDIRECT_CONFIG } from '../constants';
import { useErrors } from './useErrors';
import { useMeShoppingCart } from './use-shopping-cart';
import { useShowToast } from './useShowToast';

const CREATE_SESSION_WITH_TOKEN = gql`
  mutation CreateSessionWithToken($token: String!) {
    createSessionWithToken(token: $token) {
      token
    }
  }
`;

export const useCreateSessionWithToken = () => {
  const [createSessionMutation, { data, loading }] = useMutation(CREATE_SESSION_WITH_TOKEN);
  const navigate = useNavigate();
  const { errorsHandler } = useErrors();
  const { showError } = useShowToast();
  const { fetchMeShoppingCart } = useMeShoppingCart();

  const goToCheckout = (storeFrontSlug) => {
    navigate(ROUTING_CONFIG.checkout.replace(':storeFrontSlug', storeFrontSlug), { replace: true });
  };

  const goToShoppingCart = (storeFrontSlug) => {
    navigate(ROUTING_CONFIG.cart.replace(':storeFrontSlug', storeFrontSlug), { replace: true });
  };

  const goToRecommendations = (storeFrontSlug) => {
    navigate(ROUTING_CONFIG.accountRecommendations.replace(':storeFrontSlug', storeFrontSlug), {
      replace: true,
    });
  };

  const goToHome = (storeFrontSlug) => {
    navigate(ROUTING_CONFIG.home.replace(':storeFrontSlug', storeFrontSlug), { replace: true });
  };

  const processGoToCheckout = (storeFrontSlug, shoppingCart) => {
    if (shoppingCart?.length) {
      goToCheckout(storeFrontSlug);
    } else {
      showError('Unable to open Checkout Page. Shopping Cart is empty');
      goToHome(storeFrontSlug);
    }
  };

  const processGoToCart = (storeFrontSlug, shoppingCart) => {
    if (shoppingCart?.length) {
      goToShoppingCart(storeFrontSlug);
    } else {
      showError('Unable to open Shopping Cart Page. Shopping Cart is empty');
      goToHome(storeFrontSlug);
    }
  };

  const createSession = async (token, storeFrontSlug, type) => {
    try {
      const response = await createSessionMutation({
        variables: { token },
        context: {
          clientName: clientNameService.loginClientName,
        },
      });
      const newToken = response?.data?.createSessionWithToken?.token;

      if (newToken) {
        await sessionCacheStorage.setToken(newToken);
        await emailService.remove();

        if (storeFrontSlug) {
          slugService.slug = storeFrontSlug;
        }

        // Should be called after `newToken` fetched!
        const shoppingCart = await fetchMeShoppingCart();

        // Unfortunately, we have to recheck `shoppingCart` here like that.
        //   Without it it just continue script where shoppingCart === undefined.
        //   I have no idea why.(
        if (shoppingCart) {
          if (type === REDIRECT_CONFIG.recommendations) {
            goToRecommendations(storeFrontSlug);
          } else if (type === REDIRECT_CONFIG.shopping_cart) {
            processGoToCart(storeFrontSlug, shoppingCart);
          } else if (type === REDIRECT_CONFIG.checkout) {
            processGoToCheckout(storeFrontSlug, shoppingCart);
          } else if (type === undefined) {
            goToHome(storeFrontSlug);
          } else {
            showError('Wrong Route Type has been passed');
            goToHome(storeFrontSlug);
          }

          return newToken;
        }
      }
    } catch (error) {
      errorsHandler(error);
      sessionCacheStorage.removeToken();
      clientNameService.removeClientName();
      navigate(ROUTING_CONFIG.home.replace(':storeFrontSlug', storeFrontSlug), { replace: true });
    }
  };

  return {
    createSession,
    isLoading: loading,
    ...data,
  };
};
