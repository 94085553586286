import { LOCAL_STORAGE_KEYS, ROUTING_CONFIG } from '../constants';
import { sessionCacheStorage } from './session-cache-service';
import { slugService } from './slug-service';

class ClientNameService {
  _clientName;

  getStoreFrontSlug() {
    if (slugService.slug) return slugService.slug;

    const { pathname } = window.location;
    const splittedPath = pathname.split('/').filter((item) => item);

    if (splittedPath?.length && !pathname.includes(ROUTING_CONFIG.notFound)) {
      const [slug] = splittedPath;
      return slug;
    }
  }

  constructor() {
    this._clientName = localStorage.getItem(
      LOCAL_STORAGE_KEYS.clientName + this.getStoreFrontSlug(),
    );
  }

  _updateServiceState(value) {
    this._clientName = value;
  }

  set clientName(value) {
    const slugName = slugService.slug;
    try {
      localStorage.setItem(LOCAL_STORAGE_KEYS.clientName + slugName, value);
      this._updateServiceState(value);
    } catch (error) {
      console.error('Error setting clientName in localStorage:', error);
    }
  }

  get clientName() {
    if (!sessionCacheStorage.hasToken) return null;

    const slugName = slugService.slug;
    try {
      return localStorage.getItem(LOCAL_STORAGE_KEYS.clientName + slugName) || null;
    } catch (error) {
      console.error('Error getting clientName from localStorage:', error);
      return null;
    }
  }

  get loginClientName() {
    const slugName = slugService.slug;
    try {
      return localStorage.getItem(LOCAL_STORAGE_KEYS.clientName + slugName) || null;
    } catch (error) {
      console.error('Error getting clientName from localStorage:', error);
      return null;
    }
  }

  get isWholesaleClient() {
    return this._clientName === 'wholesale';
  }

  removeClientName() {
    const slugName = slugService.slug;
    try {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.clientName + slugName);
      this._updateServiceState(null);
    } catch (error) {
      console.error('Error removing clientName from localStorage:', error);
    }
  }
}

export const clientNameService = new ClientNameService();
