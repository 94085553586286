import React from 'react';
import { SearchIcon } from '../icons/Icons';

export const SearchField = (props) => {
  return (
    <div className="relative w-full lg:w-3/5">
      <input
        {...props}
        type="text"
        placeholder="Search for anything"
        className="border-light-gray h-8 w-full rounded-full border bg-white py-2 pl-10 text-sm italic leading-5 text-gray-500 focus:text-gray-700 focus:outline-none"
      />
      <div className="absolute left-2 top-1/2 -translate-y-1/2 transform">
        <SearchIcon className="h-4 w-4 text-gray-600" />
      </div>
    </div>
  );
};
