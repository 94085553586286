import React, { useContext } from 'react';
import { getLocalStoreFrontSlug, setPlaceholderImageOnError } from '../../utils';
import { Context } from '../../store';
import { useNavigate } from 'react-router-dom';

export const HeaderLogo = () => {
  const { state } = useContext(Context);
  const { config } = state || {};
  const navigate = useNavigate();
  const storeFrontSlug = getLocalStoreFrontSlug();
  const { name = '', logoImage, longLogoImage } = config?.general ?? {};
  const logoImageUrl = longLogoImage?.url || logoImage?.url;

  const clickHandler = () => {
    navigate(`/${storeFrontSlug}`);
  };

  return (
    <>
      {logoImageUrl ? (
        <img
          src={logoImageUrl}
          alt={name}
          onError={setPlaceholderImageOnError}
          className="h-10 cursor-pointer object-contain"
          onClick={clickHandler}
        />
      ) : (
        <div className="cursor-pointer text-base font-semibold text-black" onClick={clickHandler}>
          {config?.general.name}
        </div>
      )}
    </>
  );
};
