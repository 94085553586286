import React from 'react';
import { BurgerIcon, CrossIcon } from '../icons/Icons';
import { HeaderLogo } from '../main-layout/header-logo';
import { IconsBlock } from './icons-block';

export const MobileHeader = ({ toggleMenu, isMenuOpen }) => {
  return (
    <div>
      <div className="flex h-16 items-center justify-between py-2 pl-5 pr-1">
        <div
          className="flex h-7 w-8 items-center justify-center rounded-full bg-primary"
          onClick={toggleMenu}
        >
          {isMenuOpen ? <CrossIcon /> : <BurgerIcon />}
        </div>
        <div className="flex w-full items-center justify-between pl-4 pr-2">
          <div className="flex-shrink-0">
            <HeaderLogo />
          </div>
          <IconsBlock onClick={toggleMenu} isMenuOpen={isMenuOpen} />
        </div>
      </div>
    </div>
  );
};
