import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Spinner } from '../spinner';
import { useCreateSessionWithOneTimeCode } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    border: '1px solid',
    height: '4rem',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    caretColor: 'transparent',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    fontSize: '2rem',
    color: '#000',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '1.3rem',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.5rem',
      width: '80%',
    },
    outline: 'none',
  },
}));

export const OtpForm = ({ email }) => {
  const classes = useStyles();
  const { createSession, isLoading } = useCreateSessionWithOneTimeCode();
  const [otpCode, setOtp] = useState('');
  const ref = useRef();

  useEffect(() => {
    ref.current.focus();
  }, []);

  useEffect(() => {
    if (otpCode.length === 6) {
      createSession(email, otpCode);
    }
  }, [otpCode]);

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setOtp(value);
  };

  return (
    <div className={classes.container}>
      {isLoading && <Spinner />}
      <input
        ref={ref}
        type="text"
        maxLength="6"
        value={otpCode}
        onFocus={(e) => e.target.scrollTo(0, 0)}
        inputMode="numeric"
        onChange={handleChange}
        className={classes.input}
      />
    </div>
  );
};
